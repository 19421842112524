import React from 'react';
import "react-table-6/react-table.css";
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import AISearch from './aiSearch';
import Icon from '@material-ui/core/Icon';


export default function AISearchModal(props) {
    console.log('props', props)
    const closeAISearch = () => {
        props.modalCallBack(false);
    };

return (
  <div>
    <Modal
      open={props.showAiSearch}
      onClose={closeAISearch}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.showAiSearch}>
        {/* <AISearch /> */}
        <div className="customModal">
          <div className="header">
            <h2 className="text-center">AI Search</h2>
            <Icon
              onClick={() => {
                closeAISearch();
              }}
            >
              close
            </Icon>
          </div>
          <div className="modal-content pt-5">
            <AISearch />
          </div>
        </div>
      </Fade>
    </Modal>
  </div>
);
}

