import React, { useEffect, useRef } from "react";
import { useTable, useExpanded, usePagination, useResizeColumns, useBlockLayout } from "react-table";
import styled from "styled-components";
import Icon from '@mui/material/Icon';
import IconButton from "@mui/material/IconButton";
import NewPagination from "../CustomPagination";
import './reactTable.scss';

const Styles = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid  rgba(0,0,0,0.07);
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    tbody tr:hover{
      background: rgba(0,0,0,0.05)!important;
    }
    tr:nth-child(even) {
      background: #f7f7f7;
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid rgba(0,0,0,0.07);
      border-right: 1px solid rgba(0,0,0,0.07);
      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        /* background: black; */
        width: 1px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        ${"" /* prevents from scrolling while dragging on touch devices */}
        touch-action:none;

        &.isResizing {
          background: white;
        }
      }
    }
  }
`;

function NewReactTable({ columns: userColumns, data, handleSorting, scrollFetch,isFetching, hidePagination, tablePageSize }) {

  const tableContainerRef = useRef(null);

  const handleColumnHeaderClick = (colInfo) => {
    if (colInfo.id && colInfo.id != "comments" && colInfo.id != "expander" && colInfo.id != "description" && colInfo.id != "yearmonth_new_masked") {
      //handleSorting(colInfo.id)
    }
  }

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 400
    }),
    []
  );


  const columnHeaderClick = (state, someData, colInfo) => {

    return {
      onClick: (e) => {
        handleColumnHeaderClick(colInfo)
      },
      style: { cursor: 'pointer' }
    }
  }

  const fetchMoreOnBottomReached = (e) => {
    if (scrollFetch) {
      scrollFetch(e)
    }
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    pageCount,
    prepareRow,
    gotoPage,
    setPageSize,
    state: { expanded, pageIndex, pageSize  },

  } = useTable(
    {
      columns: userColumns,
      data
    },
    useBlockLayout,
    useResizeColumns,
    useExpanded, 
    usePagination // Use the useExpanded plugin hook
  );

  useEffect(()=>{
    setPageSize(tablePageSize ? tablePageSize : pageSize)
  },[data])

  return (
    <div className="table-wrapper">
      <Styles>
      <div className="table-container"
        onScroll={e => fetchMoreOnBottomReached(e.currentTarget)}
        ref={tableContainerRef}>
        <table {...getTableProps()} >
          
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps({
                      onClick: (e) => {
                        handleColumnHeaderClick(column)
                      },
                      style: { cursor: 'pointer' }
                    })

                    }>{column.render("Header")}
                    <div
                  {...column.getResizerProps()}
                  className={`resizer ${column.isResizing ? "isResizing" : ""}`}
                />
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>

              {
                hidePagination ?
                rows.map((row, i, toggleRowExpanded) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps({
                        className: (row.canExpand ? "hasChild" : ""),
                        onClick: () => {
                          row.toggleRowExpanded();
                        }
                      })}>
                        {row.cells.map(cell => {
                          return (
                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                          );
                        })}
                      </tr>
                    );
                  })
                :
                  page.map((row, i, toggleRowExpanded) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps({
                        className: (row.canExpand ? "hasChild" : ""),
                        onClick: () => {
                          row.toggleRowExpanded();
                        }
                      })}>
                        {row.cells.map(cell => {
                          return (
                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                          );
                        })}
                      </tr>
                    );
                  })
              }
            </tbody>
        </table>
        </div>
      </Styles>
      { hidePagination ? 
          scrollFetch && isFetching?<div className="loading">Loading more ...</div>:""
      :  
      <div className={'ReactTable'}>
        <NewPagination pageSize={pageSize} nextText={'Next'} previousText={'Previous'} data={data} page={pageIndex} pages={pageCount} onPageChange={pageIndex => gotoPage(pageIndex)} ></NewPagination>
      </div>
      }
    </div>
  );
}
export default NewReactTable;