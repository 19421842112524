import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import TableComponent from "./NewReactTable/reactTable.jsx";
import { toastr } from 'react-redux-toastr';
import "./aiSearch.scss";
import CircularProgress from "@mui/material/CircularProgress";
import { CONFIG } from "../../../../config.js";

const AISearch = () => {
  const [searchInput, setSearchInput] = useState("");
  const [query, setQuery] = useState("");
  const [tableHeaderData, setTableHeaderData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [firstSearchState, setFirstSearchState] = useState(true);
  // const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSend = async () => {
    if (searchInput.length > 0) {
      setErrorMessage("");
      setLoading(true);
     
      try {
        const response = await fetch(`${CONFIG.api_base_url}v1/redirect/ai/query-mongo/`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ user_query: searchInput }),
        });
        const result = await response.json();
        console.log('Generated Query', JSON.stringify(result.query))
        if (result.message || result.error) {
          setQuery("");
          setTableHeaderData([]);
          setRowData([]);
          setFirstSearchState(true);
          const errorMessage = `${result.message ? result.message : ''} ${result.error ? result.error : ''}`
          setErrorMessage(errorMessage)
        } else {
          setQuery(result.query || "");
          setTableHeaderData(result.results.row_header);
          setRowData(result.results.row_data);
          if (result.results.row_data.length === 0) {
            toastr.info('No Records Found', {timeOut:10000})
          }
          setFirstSearchState(false);
          console.log('rowData',  result.results.row_data)
          console.log('rowHeader',  result.results.row_header)
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    } else {
      toastr.info("Please Enter Search Query", { timeOut: 2000 });
    }
  };

  const changeKeysOfHeader = (data) => {
    if (data?.length) {
      const tableElement = document.getElementsByClassName("table-region")[0];
      const tableWidth = tableElement
        ? tableElement.clientWidth * (1 / data.length)
        : 500; // Default width
      const headerList = data.map((item) => {
        return {
          ...item,
          Header: (props) => <div>{item.label}</div>,
          id: item.key,
          width: tableWidth,
          Cell: (props) => {
            const rowData = props.row.original;
            return (
              <div className="flex ai-center jc-space-evenly text-capitalize">
                {<div style={{overflow:'auto', wordWrap:'break-word'}}>{typeof rowData[item.key] === 'string' ? rowData[item.key] : JSON.stringify(rowData[item.key])}</div>}
              </div>
            );
          },
        };
      });
      return headerList;
    }
    return [];
  };

  const headerData = changeKeysOfHeader(tableHeaderData);

  return (
    <div className={`ai-search ${firstSearchState ? "first" : "subsequent"}`}>
      <div>
        <div
          style={{
            padding: 20,
            maxWidth: 600,
            margin: "auto",
            display: "flex",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <TextField
            label="Search Query"
            variant="outlined"
            placeholder="Enter Search Query and Click Send"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            size="small"
            sx={{ width: "600px" }}
            multiline
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSend}
            size="small"
          >
            Send
          </Button>
        </div>
        {loading && (
          <div
            className="box-center text-center"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            Searching <CircularProgress size={"25px"} />
          </div>
        )}
      </div>
      {errorMessage && <div className="" style={{wordWrap:'break-word'}}> {errorMessage} </div>}
      <div
        className="table-data-master table-region"
        style={{ width: "100%", maxWidth: "100%" }}
      >
        {rowData.length > 0 && (
          <div className="xpna-table-wrapper">
            <TableComponent
              columns={headerData}
              data={rowData}
              className=""
              hidePagination={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AISearch;
