import React, { useState } from "react";
import ReactPaginate from 'react-paginate';
import './DataTable.scss';
// import SendIcon from '@mui/icons-material/Send';
import SendIcon from '@material-ui/icons/Send';
import IconButton from '@mui/material/IconButton';

const PaginationComponent = (props) => {
    const [gotoPage, setGotoPage] = useState('');

    const pageNumber = props.page;
    const total = props.data?.length;
    const count = props.pageSize || 10;
    const currPageStartValue = pageNumber * count;
    const pageCount = props.pages

    const handlePaginationChange = (data) => {
        props.onPageChange(data.selected);
    }


    const handleGotoPageChange = (e) => {
        setGotoPage(e.target.value);
    };

    const handleGotoPageSubmit = (e) => {
        e.preventDefault();
        const page = Math.max(0, Math.min(parseInt(gotoPage, 10) - 1, pageCount - 1));
        if (!isNaN(page)) {
            props.onPageChange(page);
        }
    };

    return (
        <div className='dt-pagination-container'>
            <div className='dt-pagination-container-left'>
                <ReactPaginate
                    forcePage={pageNumber}
                    disableInitialCallback={true}
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePaginationChange}
                    containerClassName={'dt-pagination'}
                    pageLinkClassName={'dt-page-link'}
                    activeClassName={'active'}
                    previousClassName={'dt-prev-page'}
                    previousLinkClassName={'dt-prev-page-link'}
                    nextClassName={'dt-next-page'}
                    nextLinkClassName={'dt-next-page-link'}
                    disabledClassName={'disabled'}
                />
            </div>
            <div className='dt-pagination-container-right-center'>
                <form onSubmit={handleGotoPageSubmit}>
                    <label htmlFor="gotoPageInput">Go to page:</label>
                    <input
                        id="gotoPageInput"
                        type="number"
                        value={gotoPage}
                        onChange={handleGotoPageChange}
                        min="1"
                        max={pageCount}
                        style={{ marginLeft: '10px', marginRight: '10px' }}
                    />
<IconButton color="primary" size="x-small" aria-label="upload picture" type="submit" className='px-0 mx-0' >
                    <SendIcon fontSize="small" />

                </IconButton>
                </form>
                
            </div>
            <div className='dt-pagination-container-right'>

                <p>
                    {currPageStartValue + 1} -{' '}
                    {currPageStartValue + count > total
                        ? total
                        : currPageStartValue + count}{' '}
                    of {total} items
                </p>
                {" "}

            </div>
        </div>
    )
}

export default PaginationComponent;


